.header-color {
  background: #667db6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #667db6, #0082c8, #0082c8, #667db6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #667db6, #0082c8, #0082c8, #667db6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* Landing Page Styling*/ 

.landing-grid {
  background: #667db6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #667db6, #0082c8, #0082c8, #667db6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #667db6, #0082c8, #0082c8, #667db6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.avatar-img {
  padding-top: 55px;
  height: 300px;
  width: auto;
}

.landing-grid h2 {
  font-family: 'Anton', sans-serif;
}

.landing-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.landing-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
  font-size: small;
  text-size-adjust: auto;
}

.contact-list i{
  font-size: 50px;
  padding-right: 5rem;
  text-align: left;
}

.social i{
  font-size: 58px;
}
.landing-grid a{
  color: black;
  padding: 3px;
}

.contact-list a{
  color: black;
  font-size: 22px;
  font-family: 'Anton';
}

/* iPhone 11 Pro Max */
@media only screen and (max-width: 1024px){
  
  .contact-list i {
    font-size: 30px;
  }

  .social i {
    font-size: 38px;
  }  

  .contact-list a{
    font-size: 18px;
    text-align: left;
  }

  .contact-grid p {
    text-align: left;
  }

}

/* iPhone X */
@media only screen and (max-width: 400px){
  .contact-list a{
    font-size: 11px;
  }

  .contact-list i {
    font-size: 28px;
  }

  .contact-grid p {
    text-align: left;
  }
}

/* Landing Page Styling*/ 


/* Projects Page Styling*/

.category-tabs {
  background: #667db6;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #667db6, #0082c8, #0082c8, #667db6);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #667db6, #0082c8, #0082c8, #667db6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 20px;
}

.category-tabs strong{
  padding-bottom: 50px;
  bottom: 0;
}

.category-tabs h1{
  color:black;
}

.projects-grid {
  display: flex;
  text-align: left;
  padding-bottom: 50px;
  margin: auto;
}

/* iPhone 11 Pro Max */
@media only screen and (max-width: 1024px){
  .projects-grid {
    flex-direction: column;
  }
}
/* Projects Page Styling*/